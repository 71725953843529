import { MaybeRef } from "@vueuse/core";
import { gql } from "@urql/core";
import { useQuery } from "@urql/vue";

import { ProductInterface } from "~/api/interfaces";

const productQuery = gql`
	query ($productSlug: IdOrSlug!, $producerSlug: IdOrSlug!) {
		product(id: $productSlug, producer: $producerSlug) {
			id
			slug
			name
			state
			description
			descriptionAllergens
			descriptionIngredients
			descriptionNutrition

			asset {
				id
				... on MediaAssetInterface {
					publicId
				}
			}

			price {
				base
				measure
			}

			producer {
				id
				name
				slug
			}

			variations {
				id
				amount
				quantity
				price
				stock
			}

			category {
				id
				name
				slug
				parent {
					name
				}
			}
		}
	}
`;

export function useProduct(productSlug?: MaybeRef<string>, producerSlug?: MaybeRef<string>) {
	if (!productSlug || !producerSlug) {
		const route = useRoute();
		if (!productSlug) productSlug = route.params.productSlug?.toString();
		if (!producerSlug) producerSlug = route.params.producerSlug?.toString();
	}

	const { data, executeQuery } = useQuery<{ product: ProductInterface }>({
		query: productQuery,
		variables: { productSlug, producerSlug },
		pause: computed(() => !unref(productSlug) || !unref(producerSlug)),
	});

	const product = computed(() => data.value?.product);

	return { product, executeQuery };
}
