/**
 * Generate a random string that is cryptographically strong using Web Crypto API.
 *
 * The function generates a list of random numbers that will be converted as character codes.
 *
 * @param minLength
 * @param maxLength
 */
export function generateRandomString(minLength = 18, maxLength = 36) {
	const random = () => {
		// Use Web Crypto API to get cryptographically strong random values. Parameter must be typed array.
		const randomNumbers = crypto.getRandomValues(new Uint8Array(maxLength));
		// Filter only printable character codes.
		const filtered = randomNumbers.filter((n) => n > 32 && n < 127);
		// Convert character codes to string.
		return String.fromCharCode(...filtered);
	};
	let secret = random();

	// Keep appending to string until minimum length is achieved.
	while (secret.length < minLength) secret += random();

	return secret;
}
