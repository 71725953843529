<script setup lang="ts">
import { ToastInterface, useToast } from "~/utils";

const props = defineProps<{
	toast: ToastInterface;
}>();
const { remove } = useToast();

useTimeoutFn(() => dismiss(), props.toast.timeout ?? 4000);

const dismiss = () => remove(props.toast.id);
</script>

<template>
	<transition name="fade">
		<div class="d-toast" :class="toast.type">
			<icon-ic-round-clear class="icon" @click.prevent="dismiss" />
			<p class="title">{{ toast.title }}</p>
			<p v-if="toast.message" class="message">{{ toast.message }}</p>
		</div>
	</transition>
</template>

<style lang="scss">
.d-toast {
	min-height: 6rem;
	width: 18rem;
	padding: 1.3rem;
	margin-bottom: 0.5rem;
	background-color: #333;
	border-radius: 0.6rem;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	letter-spacing: 0.4px;
	font-size: 1.3rem;
	opacity: 0.85;
	cursor: default;

	.title {
		font-weight: 600;
	}

	.message {
		margin-top: 0.2em;
	}

	&.success {
		background: #3a943e;
	}
	&.error {
		background: #900;
	}

	.icon {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.6em;
		font-size: 1.5rem;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
