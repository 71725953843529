import "vue-router";
import type { NavigationGuardNext, RouteLocationNormalized, RouterOptions } from "vue-router";

import { ModuleInstallFunctionSignature } from "~/interfaces";
import { SessionStore, useSessionStore } from "~/store/session";

declare module "vue-router" {
	interface RouteMeta {
		layout?: string;
		auth?: boolean;
	}
}

let sessionStore: SessionStore;

function globalAuthenticationGuard(
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext,
) {
	return to.meta.auth ? isAuthenticatedGuard(to, from, next) : next();
}

function isAuthenticatedGuard(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
	if (sessionStore.isLoggedIn) return next();
	next({
		name: "user-login",
		query: { return: encodeURIComponent(to.fullPath) },
	});
}

export const install: ModuleInstallFunctionSignature = ({ router, pinia }) => {
	sessionStore = useSessionStore(pinia);
	router.beforeEach(globalAuthenticationGuard);
};

export const routerOptions: Partial<RouterOptions> = {
	scrollBehavior: (to, _from, savedPosition) => {
		const behavior = savedPosition ? savedPosition : to.hash ? { selector: to.hash } : { top: 0, behavior: "smooth" };
		return { ...behavior, behavior: "smooth" };
	},
};
