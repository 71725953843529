import { createSlider } from "@typeform/embed";
import "@typeform/embed/build/css/slider.css";

export function useEventOrganizerLeadTypeform() {
	return createSlider("KaOK2gil");
}

export function useMissingEventLeadTypeform() {
	return createSlider("LqcFFXbT");
}

export function useHomeDeliveryTypeform() {
	return createSlider("QEiXqGJT");
}
