import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import { ModuleInstallFunctionSignature } from "~/interfaces";

export const install: ModuleInstallFunctionSignature = ({ app }) => {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN as string,
		environment: import.meta.env.MODE,
		// release: "my-project-name@" + process.env.npm_package_version,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0.2,
	});

	app.config.errorHandler = (err, _vm, _info) => {
		Sentry.captureException(err);
		console.error(err);
	};

	if (!import.meta.env.SSR) {
		window.addEventListener("error", (event) => {
			Sentry.captureException(event);
			console.error(event);
		});

		window.addEventListener("unhandledrejection", (event) => {
			Sentry.captureException(event);
			console.error(event);
		});
	}
};
