<script setup lang="ts">
import { useCssVars } from "vue";
import { useCloudinaryImage } from "~/utils/use-cloudinary-image";

const background = useCloudinaryImage("content/cabbage_p58h3u", ["c_fill", "w_1600", "h_800"]);

useCssVars(() => ({
	"etma-landing-page-intro-background-src": `url(${background.value})`,
}));
</script>

<template>
	<div class="etma-landing-page-intro">
		<div class="etma-landing-page-intro--contents">
			<d-title class="etma-landing-page-intro--text">{{ $t("landing-page.intro.header") }}</d-title>
			<d-btn huge accent :to="{ name: 'events' }">{{ $t("landing-page.goto-all-events") }}</d-btn>
		</div>
	</div>
</template>

<style lang="scss">
.etma-landing-page-intro {
	background-position: center center;
	background-image: var(--etma-landing-page-intro-background-src);
	background-size: cover;
	box-sizing: border-box;
	padding: 3rem 0;
	position: relative;

	> * {
		position: relative;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(180deg, rgba(58, 45, 45, 0.57) 22.92%, rgba(196, 196, 196, 0) 87.5%);
		background-blend-mode: darken;
		content: "";
	}

	&--contents {
		margin: 0 auto;
		max-width: 100%;
		width: fit-content;
		text-align: center;
	}

	&--text {
		color: white;
		max-width: 80rem;

		& > h1 {
			font-weight: bold;
		}
	}

	@media screen and (min-width: $breakpoint-mobile-max) {
		padding: 10%;
	}

	@media screen and (min-width: $breakpoint-large-min) {
		padding: 10% 20%;
	}

	@media screen and (max-width: 60rem) {
		text-align: center;
	}
}
</style>
