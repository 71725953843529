

import __layout_0 from '/home/tdolsen/Projects/etma/etma/applications/web/src/components/layouts/default.vue'
export const layouts = {
'content': () => import('/home/tdolsen/Projects/etma/etma/applications/web/src/components/layouts/content.vue'),
'default': __layout_0,
'tiny-content': () => import('/home/tdolsen/Projects/etma/etma/applications/web/src/components/layouts/tiny-content.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
