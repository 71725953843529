<script setup lang="ts">
import { watch } from "vue";
import { useRoute } from "vue-router";

import { createNotificationsStore, createSessionStore } from "~/store";

const route = useRoute();

if (!import.meta.env.SSR) {
	const session = createSessionStore();
	createNotificationsStore();

	watch(
		route,
		() => {
			if ("preview" in route.query && typeof route.query.preview === "string")
				session.setProducerPreview(route.query.preview);
		},
		{ immediate: true },
	);
}
</script>

<template>
	<router-view v-slot="{ Component }">
		<suspense>
			<etma-app>
				<component :is="Component" />
			</etma-app>
		</suspense>
	</router-view>
</template>

<style lang="scss">
#app {
	height: 100vh;
}
</style>
