import { MaybeRef } from "@vueuse/core";
import { HeadAttrs, HeadObject, useHead as vueUseHead } from "@vueuse/head";
import { isRef, unref, computed } from "vue";
import { useI18n } from "vue-i18n";

export type TitleType = MaybeRef<string | MaybeRef<string | undefined>[] | undefined>;
export interface HeadOptions {
	title?: TitleType;
	description?: MaybeRef<string>;
	type?: "article" | "product" | "website";
	image?: MaybeRef<string>;
}

const SITE_NAME = "Etma.no";

function metaTag(name: string, content: MaybeRef<string>): HeadAttrs {
	return { name, content };
}
function ogMetaTag(property: string, content: MaybeRef<string>): HeadAttrs {
	return { property: `og:${property}`, content };
}

function compileTitle(title?: TitleType, extraSegments: string[] = []) {
	const i18n = useI18n();
	return computed<string>(() => {
		const t = unref(title);
		const segments = (t instanceof Array ? t : t !== undefined ? [t] : []).map((s) =>
			isRef(s) ? unref(s) : s ? i18n.t(s) : "",
		);
		segments.push(...extraSegments);
		if (segments.length === 0) return "";
		return segments.join(" | ");
	});
}

export function useHead(options: HeadOptions) {
	const meta: MaybeRef<HeadAttrs[]> = [];

	if (options.description) {
		meta.push(metaTag("description", options.description));
		meta.push(ogMetaTag("description", options.description));
	}

	if (options.image) meta.push(ogMetaTag("image", options.image));

	const head: HeadObject = {
		title: compileTitle(options.title, [SITE_NAME]),
		meta: [ogMetaTag("site_name", SITE_NAME), ogMetaTag("title", compileTitle(options.title) ?? SITE_NAME), ...meta],
	};

	vueUseHead(head);
	return head;
}

// enum MetaOpenGraphKeys {
// 	og_title = "og:title",
// 	og_description = "og:description",
// 	og_type = "og:type",
// 	og_image = "og:image",
// 	og_url = "og:url",
// 	fb_appId = "fb:app_id",
// 	og_audio = "og:audio",
// 	og_determiner = "og:determiner",
// 	og_locale = "og:locale",
// 	og_locale_alt = "og:locale:alternate",
// 	og_siteName = "og:site_name",
// 	og_video = "og:video",
// }
// enum MetaTwitterKeys {
// 	tw_card = "twitter:card",
// 	tw_imageAlt = "twitter:image:alt",
// 	tw_site = "twitter:site",
// }
// export type MetaSocialMediaTag = {
// 	[key in keyof typeof MetaOpenGraphKeys | keyof typeof MetaTwitterKeys]?: MaybeRef<string>;
// };
// export interface HeadOptions {
// 	title?: MaybeRef<string | TitleType | TitleType[]>;
// 	meta?: MetaSocialMediaTag[];
// }
