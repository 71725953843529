<script setup lang="ts">
import { useSessionStore } from "~/store/session";
const session = useSessionStore();
</script>

<template>
	<etma-app-header-actions-item
		:btn-label="session.user?.name.given || $t('user.myAccount')"
		:to="{ name: 'user-account' }"
	>
		<template #icon><icon-ph-user-duotone /></template>

		<!-- <template #dropdown>
			<d-menu v-if="session.isProducer">
				<d-menu-item
					v-for="producer in session.user?.producers"
					:key="producer.id"
					:text="producer.name"
					:to="{
						name: 'producers-producerSlug-account',
						params: { producerSlug: producer.slug },
					}"
				/>
			</d-menu>
		</template> -->
	</etma-app-header-actions-item>
</template>
