import { gql, useQuery } from "@urql/vue";
import { computed, inject, InjectionKey, provide, Ref, unref } from "vue";

import { EventDateInterface } from "~/api/interfaces";

const query = gql`
	query ($eventDateId: Int!) {
		eventDate(id: $eventDateId) {
			id
			start
			end

			producers {
				id
				organizerApproved
				producerApproved
				shopClosesAt
				producer {
					id
					name
					slug

					logo {
						id
						publicId
					}
				}
			}

			members {
				... on EventDateProducer {
					id
					organizerApproved
					producerApproved
					producer {
						id
						name
						slug

						logo {
							id
							publicId
						}
					}
				}
				... on EventDateProducerInvitation {
					id
					acceptedAt
					rejectedAt
					producer {
						id
						name

						logo {
							id
							publicId
						}
					}
				}
			}
		}
	}
`;

export const EventDateInjectionKey: InjectionKey<Ref<EventDateInterface | undefined>> = Symbol();

export function provideEventDate(eventDateId: Ref<number | undefined>) {
	const { data, executeQuery } = useQuery<{ eventDate: EventDateInterface }>({
		query,
		variables: { eventDateId },
		pause: computed(() => !eventDateId.value),
	});

	const eventDate = computed(() => data.value?.eventDate);
	provide(EventDateInjectionKey, eventDate);

	return { eventDate, executeQuery };
}

export function useEventDate() {
	const eventDate = inject(EventDateInjectionKey, null);
	const eventDateId = computed(() => unref(eventDate)?.id);
	return { eventDate, eventDateId };
}
