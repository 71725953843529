import { cacheExchange, dedupExchange, makeOperation, ssrExchange } from "@urql/core";
import { SSRExchange } from "@urql/core/dist/types/exchanges/ssr";
import { authExchange } from "@urql/exchange-auth";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import urql, { ClientOptions } from "@urql/vue";
// import { createClient as createWSClient, Sink } from "graphql-ws";
import type { Pinia } from "pinia";
import type { App } from "vue";

import { useSessionStore } from "~/store/session";

function createAuthExchange(pinia?: Pinia) {
	const session = useSessionStore(pinia);

	return authExchange<string>({
		async getAuth() {
			return session.token;
		},
		willAuthError({ authState }) {
			return !session.isLoggedIn || !authState;
		},
		didAuthError({ error }) {
			const authError = error.graphQLErrors.some((e) => e.extensions?.code === "UNAUTHENTICATED");
			if (authError) useSessionStore().unsetToken();
			return authError;
		},
		addAuthToOperation({ authState, operation }) {
			if (!authState) return operation;

			const fetchOptions =
				typeof operation.context.fetchOptions === "function"
					? operation.context.fetchOptions()
					: operation.context.fetchOptions || {};

			return makeOperation(operation.kind, operation, {
				...operation.context,
				fetchOptions: {
					...fetchOptions,
					headers: {
						...fetchOptions.headers,
						Authorization: `Bearer ${authState}`,
					},
				},
			});
		},
	});
}

export function createSSRExchange(): SSRExchange {
	return ssrExchange({
		isClient: !import.meta.env.SSR,
		initialState: import.meta.env.SSR ? undefined : window.__INITIAL_STATE__?.urql,
	});
}

// function createSubscriptionExchange(pinia: Pinia, _hostPath: string) {
// 	// const wsClient = createWSClient({ url: `ws://${hostPath}` });
// 	const wsClient = createWSClient({
// 		url: "ws://localhost:3000/graphql",
// 		connectionParams: async () => {
// 			return { token: useSessionStore(pinia).token };
// 		},
// 	});

// 	return subscriptionExchange({
// 		forwardSubscription: (operation) => ({
// 			subscribe: (sink) => ({
// 				unsubscribe: wsClient.subscribe(operation, sink as Sink),
// 			}),
// 		}),
// 	});
// }

export function createUrql(ssr: SSRExchange, app: App, pinia: Pinia, urlConfig: URL) {
	const hostPath = `${urlConfig.host}/graphql`;
	const url = import.meta.env.PROD && import.meta.env.SSR ? "http://api/graphql" : `${urlConfig.protocol}//${hostPath}`;

	app.use(urql, {
		url,
		exchanges: [
			dedupExchange,
			cacheExchange,
			createAuthExchange(pinia),
			ssr,
			// createSubscriptionExchange(pinia, hostPath),
			multipartFetchExchange,
		],
		fetchOptions() {
			const { producerPreview } = useSessionStore(pinia);
			const headers: HeadersInit = {};

			if (producerPreview) headers["X-ETMA-Producer-Preview"] = producerPreview.id;

			return { headers };
		},
	} as ClientOptions);

	return { urql: { ssr } };
}
