import useVuelidate, { Validation, ValidationArgs } from "@vuelidate/core";
import type { MaybeRef } from "@vueuse/core";
import type { Ref } from "vue";

export interface FieldsArgs {
	[key: string]: {
		validate?: ValidationArgs;
		initial?: MaybeRef<unknown>;
	};
}

export interface FormDefinition<Fields extends FieldsArgs> {
	input: InputDefinition<Fields>;
	vuelidate: Ref<Validation>;
}

export type InputDefinition<Fields extends FieldsArgs> = ExtractFieldTypes<Fields, ExtractInitial<Fields>>;

type ExtractInitial<Fields extends FieldsArgs> = {
	[K in keyof Fields]: Fields[K]["initial"] & undefined extends never ? Fields[K]["initial"] : undefined;
};

type ExtractFieldTypes<Fields extends FieldsArgs, Initial = ExtractInitial<Fields>> = {
	[K in keyof Initial]: Initial[K] extends undefined ? string : Initial[K];
};

export function useForm<Fields extends FieldsArgs>(fields: Fields) {
	// Variables to hold initial values and validation rules.
	const initial = {} as ExtractFieldTypes<Fields>;
	const validation = {} as ValidationArgs;

	// Iterate over fields and extract data.
	for (const name of Object.keys(fields) as (keyof Fields)[]) {
		const field = fields[name];
		const fieldInitial = unref(field.initial);
		initial[name] = fieldInitial !== undefined ? fieldInitial : (null as any); // eslint-disable-line
		if (field.validate) validation[name as string] = field.validate;
	}

	// Make field inputs reactive.
	const input = reactive(initial);
	provide("l-form_inject:input", input);

	// Configure validation.
	const vuelidate = useVuelidate(validation, input);
	provide("l-form_inject:vuelidate", vuelidate);

	provide("l-form_inject", { input, vuelidate });

	const validate = async () => unref(vuelidate).$validate();
	const errors = computed(() => unref(vuelidate).$errors);

	// Return.
	return { errors, input, validate, vuelidate };
}
