import { gql } from "@urql/core";
import { useQuery } from "@urql/vue";
import { EventDateProducerInvitationInterface } from "~/api/interfaces";
import { useSessionStore } from ".";

const invitationsQuery = gql`
	query GetUserEventDateInvitations {
		getUserEventDateInvitations {
			id
			acceptedAt
			eventDate {
				id
				start
				event {
					name
				}
			}
			producer {
				name
				slug
			}
		}
	}
`;

export function createNotificationsStore() {
	const session = useSessionStore();
	const notifications = useNotificationsStore();

	const { data: invitations } = useQuery<
		{ getUserEventDateInvitations: EventDateProducerInvitationInterface[] | null },
		undefined
	>({
		query: invitationsQuery,
		requestPolicy: "network-only",
		pause: computed(() => !session.isProducer),
	});

	watch(invitations, () => notifications.setInvitations(invitations.value?.getUserEventDateInvitations));

	return notifications;
}

export const useNotificationsStore = defineStore("notifications", {
	state: () => ({
		invitations: null as EventDateProducerInvitationInterface[] | null,
	}),
	getters: {
		hasNotifications: ({ invitations }) => !!(invitations?.length ?? 0 > 0),
	},
	actions: {
		setInvitations(invitations: EventDateProducerInvitationInterface[] | null | undefined) {
			const i = invitations?.filter((ei) => !ei.acceptedAt);
			this.invitations = i || null;
		},
	},
});

export type NotificationStore = ReturnType<typeof useNotificationsStore>;
