<script lang="ts" setup>
import { useCloudinaryImage } from "~/utils";

const background = useCloudinaryImage("content/picking_apples_mumecp", ["c_fill", "w_1222", "h_478"]);
</script>

<template>
	<d-cta-hook
		class="etma-landing-page-producer-hook"
		:background-image="background"
		:title="$t('landing-page.producer-hook.title')"
		:button-text="$t('landing-page.producer-hook.button')"
		:to="{ name: 'producers-register' }"
	/>
</template>
