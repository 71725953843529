<script setup lang="ts">
import { useCloudinaryImage, useHead } from "~/utils";

const i18n = useI18n();
const image = useCloudinaryImage("content/cabbage_p58h3u");
useHead({ image, description: i18n.t("landing-page.intro.header") });
</script>

<template>
	<div>
		<etma-landing-page-intro />
		<etma-landing-page-how-it-works />

		<div class="etma-landing-page-cta-hooks">
			<etma-landing-page-event-organizer-hook />
			<etma-landing-page-producer-hook />
		</div>
	</div>
</template>

<style lang="scss">
.etma-landing-page-cta-hooks {
	margin: 1rem 0;

	> * {
		margin: 2rem 1rem;
		flex: 1;
	}

	@media screen and (min-width: 60rem) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}
</style>
