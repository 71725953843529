<script lang="ts" setup>
import { useCloudinaryImage, useEventOrganizerLeadTypeform } from "~/utils";

const { open } = useEventOrganizerLeadTypeform();
const background = useCloudinaryImage("content/events/valerenga", ["c_fill", "w_1222", "h_478"]);
</script>

<template>
	<d-cta-hook
		class="etma-landing-page-event-organizer-hook"
		:background-image="background"
		:title="$t('landing-page.event-organizer-hook.title')"
		:button-text="$t('landing-page.event-organizer-hook.button')"
		:button-click="open"
	/>
</template>
