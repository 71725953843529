export * from "./use-search";

import { MeiliSearch } from "meilisearch";

import { ModuleInstallFunctionSignature } from "~/interfaces";

export let meilisearch: MeiliSearch;

export const install: ModuleInstallFunctionSignature = ({}) => {
	if (import.meta.env.SSR) return;

	meilisearch = new MeiliSearch({
		host: `${location.origin}/search`,
	});
};
