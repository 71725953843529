import { NumberFormats } from "@intlify/core-base";
import Debug from "debug";
import { App, unref } from "vue";
import { createI18n, DateTimeFormats, I18n } from "vue-i18n";

import { ModuleInstallFunctionSignature } from "~/interfaces";
import { setTimeZoneCookie } from "~/utils";

const debug = Debug("@etma:module:i18n");

// The locale to use when no other match is found.
export const DEFAULT_LOCALE = "no";

// The locales supported in the application.
export const SUPPORTED_LOCALES = ["en", "no"] as const;

// Type mapping of supported locales.
export type SupportedLocales = typeof SUPPORTED_LOCALES[number];

// Datetime localization
const datetimeFormats: { [locale: string]: DateTimeFormats } = {
	// "en-US": {
	// 	short: {
	// 		year: "numeric",
	// 		month: "short",
	// 		day: "numeric",
	// 	},
	// 	long: {
	// 		year: "numeric",
	// 		month: "long",
	// 		day: "numeric",
	// 		weekday: "short",
	// 		hour: "numeric",
	// 		minute: "numeric",
	// 	},
	// },
	no: {
		// weekday: { weekday: "long" },
		// year: { year: "numeric" },
		// month: { month: "long" },
		// day: { day: "numeric" },
		// hour: { hour: "2-digit" },
		// minute: { minute: "2-digit" },
		// second: { second: "2-digit" },
		// timeZoneName: { timeZoneName: "short", timeZone: "Europe/Oslo" },

		// date: {
		// 	day: "numeric",
		// 	month: "long",
		// 	year: "numeric",
		// },
		// time: {
		// 	hour: "2-digit",
		// 	minute: "2-digit",
		// },

		short: {
			year: "numeric",
			month: "short",
			day: "numeric",
		},
		medium: {
			year: "numeric",
			month: "short",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
		},
		long: {
			year: "numeric",
			month: "long",
			day: "numeric",
			weekday: "long",
			hour: "numeric",
			minute: "numeric",
		},
		"long-without-year-and-weekday": {
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
		},
		time: {
			hour: "2-digit",
			minute: "2-digit",
		},
	},
};

// Currency localization
const numberFormats: NumberFormats = {
	// "en-US": {
	// 	currency: {
	// 		style: "currency",
	// 		currency: "USD",
	// 		notation: "standard",
	// 	},
	// 	decimal: {
	// 		style: "decimal",
	// 		minimumFractionDigits: 2,
	// 		maximumFractionDigits: 2,
	// 	},
	// 	percent: {
	// 		style: "percent",
	// 		useGrouping: false,
	// 	},
	// },
	no: {
		currency: {
			style: "currency",
			currency: "NOK",
			useGrouping: true,
			currencyDisplay: "narrowSymbol",
			// notation: "compact",
			// compactDisplay: "short"
		},
		decimal: {
			style: "decimal",
			minimumSignificantDigits: 3,
			maximumSignificantDigits: 5,
		},
		percent: {
			style: "percent",
			useGrouping: false,
		},
	},
};

// Variable to hold the global i18n instance.
let globalInstance: I18n<any, any, any, false> | undefined = undefined;
let globalApp: App | undefined = undefined;

/**
 * Tests whether a given locale is supported in the application.
 *
 * @param locale The locale to test if is supported.
 * @returns A boolean indicating whether locale is supported.
 */
export function isSupportedLocale(locale: unknown): locale is SupportedLocales {
	return typeof locale === "string" && SUPPORTED_LOCALES.includes(locale as SupportedLocales);
}

/**
 * Installs the i18n module.
 */
export const install: ModuleInstallFunctionSignature = async ({ app }) => {
	globalApp = app;

	// Set the time zone cookie, so server can serve timezone-aware dates.
	setTimeZoneCookie();

	// Ensure locale is supported.
	// locale =
	// 	locale && SUPPORTED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;
	const locale = DEFAULT_LOCALE;
	debug("installing i18n module with locale %o", locale);

	// Load locale messages.
	const localeMessages = await importLocaleMessages(locale);
	await importLocaleComponents(locale);

	// Create i18n instance.
	const i18n = (globalInstance = createI18n({
		legacy: false,
		globalInjection: true,
		locale,
		fallbackLocale: DEFAULT_LOCALE,
		messages: {
			[locale]: localeMessages,
		},
		datetimeFormats,
		numberFormats,
	}));

	// Register with the app.
	app.use(i18n);
	debug(
		"i18n module installed; mode: %o - locales: %o - active: %o",
		i18n.mode,
		i18n.global.availableLocales,
		unref(i18n.global.locale),
	);

	debug(": %o", globalInstance.global.datetimeFormats.value);
};

// Identify locale translation imports.
const jsonImports = import.meta.glob("/locale/**/*.json");

const localeRegex = (locale: SupportedLocales) => `/${locale}(.|/)`;

/**
 * Imports translation messages for locale.
 *
 * @param locale The locale to import messages for.
 * @returns Returns a promise to resolve with all locale translation messages.
 */
async function importLocaleMessages(locale: SupportedLocales): Promise<Record<string, any>> {
	const _ = debug.extend("importLocaleMessages");
	_("importing for locale: %o", locale);

	// Array to hold import promises.
	const promises: Promise<Record<string, any>>[] = [];

	// Iterate over imports, and add if matching file or folder name.
	for (const path in jsonImports) {
		const match = path.search(new RegExp(localeRegex(locale)));
		if (match === -1) continue;

		_("match: %o", path);
		promises.push(jsonImports[path]());
	}

	// Await all promises to resolve.
	const imported = await Promise.all(promises);

	_("%o locale messages files imported:", imported.length);

	// Return the imported modules, mapped to it's exported values, and reduced to
	// one record.
	return imported.map((i) => i.default || i).reduce((p, c) => ({ ...p, ...c, user: { ...p.user, ...c.user } }));
}

function filterImportsOnLocale(
	imports: Record<string, () => Promise<{ [key: string]: any }>>,
	locale: SupportedLocales,
) {
	return Object.entries(imports).filter(([path]) => path.search(new RegExp(localeRegex(locale))) !== -1);
}

// Identify markdown translation components.
const markdownImports = import.meta.glob("/locale/**/*.md");

async function importLocaleComponents(locale: SupportedLocales) {
	const _ = debug.extend("importLocaleComponents");
	_("importing for locale: %o", locale);

	const entries = filterImportsOnLocale(markdownImports, locale);
	await Promise.all(
		entries.map(([path, promise]) =>
			promise().then((value) => {
				if (!value.default) return;
				const match = path.match(new RegExp(`${localeRegex(locale)}(.*).md$`));
				if (!match) return;
				globalApp?.component(`t-${match[2].replaceAll("/", "-")}`, value.default);
			}),
		),
	);
}

/**
 * Sets the current locale for translations.
 *
 * @param locale The locale to change to.
 * @param instance @deprecated Optional i18n instance to set locale on. If not
 * provided will use global instance.
 */
export async function setLocale(locale: SupportedLocales) {
	if (!isSupportedLocale(locale)) return;
	debug("setting locale to %o", locale);

	// Find i18n composer, either from argument or global instanace.
	const i18n = globalInstance?.global;
	debug(
		"active locale on instance %o: %o (is global: %o)",
		i18n?.id,
		i18n?.locale,
		// i18n?.isGlobal,
	);

	// Stop if there's no instance of i18n around.
	if (!i18n) throw TypeError("invalid i18n instance");

	// Import messages for locale, and set on i18n instance.
	const messages = await importLocaleMessages(locale);
	i18n.setLocaleMessage(locale, messages);
	debug("messages for locale %o set with %o keys", Object.keys(messages).length);

	// Import components
	// await importLocaleComponents(locale);

	// Set locale to new value on instance.
	i18n.locale = locale;
	debug("locale set to new value %o", locale);

	// Update lang attribute on html tag, if not on server.
	if (!import.meta.env.SSR) {
		const html = document.querySelector("html");
		if (html) html.setAttribute("lang", locale);
	}

	// Update language headers for GraphQL/Axios/whatever.
	// axios.defaults.headers.accept-langauage = locale...
}
