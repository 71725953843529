import { useCookies } from "~/utils/use-cookies";

/**
 * Sets time zone cookie
 *
 * Does not run on the server, as the client's time zone is not known there.
 * Uses the provided `tz` parameter as cookie value, or value returned by
 * `useTimeZone` if undefined.
 *
 * @param tz The time zone to set.
 */
export function setTimeZoneCookie(tz?: string) {
	if (import.meta.env.SSR) return;
	useCookies().set("tz", tz ?? useTimeZone(), { maxAge: 100 * 24 * 60 * 60, secure: true, sameSite: "strict" });
}

/**
 * Returns the current time zone.
 *
 * On the server tries to use the time zone defined in the `tz` cookie, falling
 * back to the local time zone if not defined, used by default in browser.
 */
export function useTimeZone() {
	if (import.meta.env.SSR) {
		const tzCookie = useCookies().get("tz");
		if (tzCookie) return tzCookie;
	}
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
