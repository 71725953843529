import { useNProgress } from "@vueuse/integrations/useNProgress";
import type { NavigationGuardWithThis, NavigationHookAfter } from "vue-router";

import { ModuleInstallFunctionSignature } from "~/interfaces";

export const install: ModuleInstallFunctionSignature = ({ router }) => {
	if (!import.meta.env.SSR) {
		const { start, done } = useNProgress();
		router.beforeEach(start as NavigationGuardWithThis<unknown>);
		router.afterEach(done as unknown as NavigationHookAfter);
	}
};
