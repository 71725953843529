import { H } from "highlight.run";

import { ModuleInstallFunctionSignature } from "~/interfaces";

export const install: ModuleInstallFunctionSignature = () => {
	if (import.meta.env.SSR || import.meta.env.DEV) return;

	H.init("y2d1p4dr", {
		enableStrictPrivacy: false,
		environment: import.meta.env.MODE,
	});
};
