import devalue from "@nuxt/devalue";
import { createHead } from "@vueuse/head";
import ViteSSR, { ClientOnly } from "vite-ssr";

import generatedPages from "virtual:generated-pages";
import { setupLayouts } from "virtual:generated-layouts";

import { AppContext } from "./interfaces";
import App from "./App.vue";
import { createPinia } from "./modules/pinia";
import { routerOptions } from "./modules/router";
import { createSSRExchange, createUrql } from "./modules/urql";
import { createCookies } from "~/utils/use-cookies";

export default ViteSSR(
	App,
	{
		routes: setupLayouts(generatedPages),
		transformState(state) {
			return import.meta.env.SSR ? devalue({ ...state, urql: state.urql?.ssr?.extractData() }) : state;
		},
		routerOptions,
	},
	async (params) => {
		const { app, initialState, url } = params;

		// Create instance of universal-cookies, hooking into useCookies util func.
		if (import.meta.env.SSR) createCookies(params.request);

		// Activate PWA if not rendering on server.
		// if (!import.meta.env.SSR) import("./pwa");

		// Enable performance tracing in development environment.
		app.config.performance = !!import.meta.env.DEV;

		// Create Pinia instance, and attach to ctx.
		const pinia = createPinia(app);

		const ssrExchange = createSSRExchange();
		if (import.meta.env.SSR) initialState.urql = { ssr: ssrExchange };
		createUrql(ssrExchange, app, pinia, url as URL);

		const ctx: AppContext = {
			...params,
			url: url as URL,
			pinia,
		};

		// Auto install custom defined modules.
		const modules = import.meta.globEager("./modules/{*.ts,*/index.ts}");
		await Promise.all(Object.values(modules).map((i) => i.install?.(ctx)));

		// Setup ssr client only component. TODO: Move to plugin?
		app.component(ClientOnly.name, ClientOnly);

		// Set up head tags.
		const head = createHead();
		app.use(head);

		return { head };
	},
);
