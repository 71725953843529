<script setup lang="ts">
import { useSessionStore } from "~/store";

const session = useSessionStore();

const stopPreview = () => {
	session.producerPreview = null;
	setTimeout(() => window.location.reload());
};
</script>

<template>
	<div v-if="session.producerPreview" class="etma-producer-preview-notice">
		<p>
			{{
				$t("producer.preview.notice.text", {
					name: session.producerPreview.name,
				})
			}}
		</p>
		<d-btn text @click="stopPreview">{{ $t("producer.preview.notice.stopPreview") }}</d-btn>
	</div>
</template>

<style lang="scss">
.etma-producer-preview-notice {
	padding: 0.3rem 1rem;
	background: #eee;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
