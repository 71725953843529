<script setup lang="ts">
import { useNotificationsStore, useSessionStore } from "~/store";
const session = useSessionStore();
const notification = useNotificationsStore();
const mounted = ref(false);
onMounted(() => (mounted.value = true));
</script>

<template>
	<div class="etma-app-header-actions">
		<etma-app-header-actions-notifications v-if="mounted && session.isProducer && notification.hasNotifications" />
		<etma-app-header-actions-producer-account-picker v-if="mounted && session.isProducer" />
		<etma-app-header-actions-user-account-menu v-if="mounted && session.isLoggedIn" />

		<template v-else>
			<etma-app-header-actions-item
				to="/user/signup"
				:btn-label="$t('authentication.signup')"
				btn-label-show-on-mobile
				highlight
			/>

			<etma-app-header-actions-item to="/user/login" :btn-label="$t('authentication.login')">
				<template #icon><icon-mdi-login-variant /></template>
			</etma-app-header-actions-item>
		</template>
	</div>
</template>

<style lang="scss">
.etma-app-header-actions {
	display: flex;
	// margin-right: calc(var(--etma-app-header-padding) / 2);
}
</style>
