import { gql } from "@urql/core";
import { useQuery } from "@urql/vue";
import type { InjectionKey, Ref } from "vue";

import { ProducerInterface, ProductInterface } from "~/api/interfaces";
import { CrumbsDefinition, useCrumbs } from "./use-breadcrumbs";

const producerQuery = gql`
	query ($producerSlug: IdOrSlug!) {
		producer(id: $producerSlug) {
			id
			name
			slug
			approved
			rekoId
			shortDescription
			fullDescription
			shopClosesHoursBeforeEvent

			logo {
				id
				publicId
			}

			header {
				id
				publicId
			}

			contact {
				email
				address
				phone
				website
				facebook
				instagram
			}
		}
	}
`;

export const ProducerInjectionKey: InjectionKey<Ref<ProducerInterface | undefined>> = Symbol();

export function provideProducer(producerSlug: Ref<string | string[]>) {
	const { data, executeQuery } = useQuery<{ producer: ProducerInterface }>({
		query: producerQuery,
		variables: { producerSlug },
		pause: computed(() => !producerSlug.value),
	});

	const producer = computed(() => data.value?.producer);
	provide(ProducerInjectionKey, producer);

	return { producer, executeQuery };
}

export function useProducer() {
	const producer = inject<Ref<ProducerInterface>>(ProducerInjectionKey);
	if (!producer) throw new Error("Producer not provided");

	const producerId = computed(() => producer.value?.id);
	const producerSlug = computed(() => producer.value?.slug);
	const producerName = computed(() => producer.value?.name);

	return {
		producer,
		producerId,
		producerSlug,
		producerName,
		useProducts: () => useProducerProducts(producerId),
	};
}

const producerProductsQuery = gql`
	query ($producerId: IdOrSlug!) {
		products(producer: $producerId) {
			id
			slug
			name
			state

			asset {
				id
				... on MediaAssetInterface {
					publicId
				}
			}

			price {
				base
				measure
			}

			producer {
				id
				name
				slug
			}

			variations {
				id
				amount
				quantity
				price
				stock
			}
		}
	}
`;

export function useProducerProducts(producerId: Ref<number>) {
	const { data, executeQuery } = useQuery<{ products: ProductInterface[] }>({
		query: producerProductsQuery,
		variables: { producerId },
		pause: computed(() => !producerId.value),
	});

	const products = computed(() => data.value?.products ?? []);

	return { products, executeQuery };
}

export function useProducerCrumbs(crumbs: CrumbsDefinition) {
	const { producer } = useProducer();
	if (!producer) return [];
	return useCrumbs({
		// _producer: "term.producer",
		"producers-producerSlug": computed(() => unref(producer)?.name),
		...Object.fromEntries(
			Object.entries(crumbs).map(([name, text]) => [
				name.startsWith("_") ? name : `producers-producerSlug-${name}`,
				text,
			]),
		),
	});
}
