import { email, numeric, required } from "@vuelidate/validators";
import type { MaybeRef } from "@vueuse/core";

import { FieldsArgs, useForm } from "./use-form";

const userFields: FieldsArgs = {
	phoneNumber: { validate: { required } },
	givenName: { validate: { required } },
	familyName: { validate: { required } },
	postalCode: { validate: { numeric } },
	email: { validate: { required, email } },
	password: { validate: { required } },
	currentPassword: { validate: {} },
	newPassword: { validate: {} },
	confirmPassword: { validate: {} },
	// newPassword: { validate: { minLength: minLength(8), requiredIfCurrentPassword: requiredIf("currentPassword") } },
	// confirmPassword: {
	// 	validate: {
	// 		/** sameAsNewPassword: sameAs("newPassword") */
	// 	},
	// },
	flagHasAcceptedMarketing: { initial: ref(true) },
};

type UserFields = typeof userFields;
type UserFieldsKey = keyof UserFields;

export function useUserForm<
	Fields extends UserFieldsKey = UserFieldsKey,
	FormFields extends FieldsArgs = Pick<typeof userFields, Fields>,
>(
	options: {
		fields?: Fields[];
		initial?: MaybeRef<{ [field: string]: MaybeRef<unknown> } | null>;
	} = {},
) {
	let { fields, initial } = options;
	if (!fields) fields = Object.keys(userFields) as Fields[];
	if (!initial) initial = {};

	return useForm<FormFields>(
		Object.fromEntries(
			Object.entries(userFields)
				.filter(([f]) => fields?.includes(f as any)) // eslint-disable-line
				.map(([k, v]) => [
					k,
					{
						...v,
						initial: initial
							? computed(() => {
									const i = unref(initial);
									return i ? i[k] : v.initial;
							  })
							: v.initial,
					}, // && initial[k] ? initial[k] : undefined },
				]),
		) as unknown as FormFields,
	);
}
