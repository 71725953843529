<script setup lang="ts">
import { useCssVars } from "vue";
import { RouteLocationRaw } from "vue-router";

const props = defineProps<{
	backgroundImage?: string;
	to?: RouteLocationRaw;
	title?: string;
	buttonClick?: (event: MouseEvent) => void;
	buttonText?: string;
}>();

useCssVars(() => ({
	...(props.backgroundImage && { "d-cta-hook-background-src": `url(${props.backgroundImage})` }),
}));
</script>

<template>
	<component :is="to ? 'router-link' : 'div'" :to="to" class="d-cta-hook" @click.prevent="buttonClick">
		<d-subtitle class="d-cta-hook__title">{{ title }}</d-subtitle>
		<d-btn v-if="buttonText" primary huge class="d-cta-hook__button" @click.prevent="buttonClick">{{
			buttonText
		}}</d-btn>
	</component>
</template>

<style lang="scss">
.d-cta-hook {
	border-radius: 1.2rem;
	background-color: antiquewhite;
	background-position: center center;
	background-image: var(--d-cta-hook-background-src);
	background-size: cover;
	cursor: pointer;
	padding: 4rem;
	max-width: 50rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	&__title {
		font-size: 1.5rem;
		color: var(--d-theme-color-offwhite);
		margin: 0;
		text-align: center;
		text-shadow: 0px 0px 5px #000000, 4px 4px 5px #00000080, -4px 4px 5px #00000080, -4px -4px 5px #00000080,
			4px -4px 5px #00000080;
	}

	&:hover &__title {
		text-decoration: underline;
	}

	&__button {
		margin-top: 2rem;
	}

	&:hover &__button {
		transform: scale(1.05);
	}

	@media screen and (min-width: $breakpoint-mobile-max) {
		padding: 5rem 4rem;
	}

	@media screen and (min-width: $breakpoint-large-min) {
		padding: 7rem 4rem;
	}
}
</style>
