import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
	persist: import.meta.env.SSR
		? undefined
		: {
				enabled: true,
				strategies: [{ key: "etma-app", storage: localStorage }],
		  },
	state: () => ({
		cartOpen: false,
		currentEventId: null as number | null,
		currentEventDateId: null as number | null,
	}),
	actions: {
		/**
		 * Cart
		 */
		openCart() {
			this.cartOpen = true;
		},
		closeCart() {
			this.cartOpen = false;
		},
		toggleCart() {
			this.cartOpen = !this.cartOpen;
		},

		/**
		 * Event
		 */
		setCurrentEventId(eventId: number | string | string[] | undefined) {
			eventId = eventId instanceof Array ? eventId[0] : eventId;
			this.currentEventId = typeof eventId === "string" ? parseInt(eventId) : eventId ?? null;
		},
	},
});

export type AppStore = ReturnType<typeof useAppStore>;
