import { MaybeRef } from "@vueuse/core";
import { computed, Ref, unref } from "vue";

export function useCloudinaryImage(publicId: MaybeRef<string>, transformations: MaybeRef<string[]> = []): Ref<string> {
	return computed(() => {
		return [
			"https://res.cloudinary.com",
			import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
			Array.isArray(transformations) && transformations.length !== 0 ? unref(transformations).join(",") : undefined,
			unref(publicId) + ".webp",
		]
			.filter((v) => v !== undefined)
			.join("/");
	});
}
