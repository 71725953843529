import { generateRandomString } from "~/utils";

type OAuthItem = {
	returnUri?: string | null;
};
type OAuthState = {
	[redirectKey: string]: OAuthItem | null;
};

export const useOAuthStore = defineStore("oauth", {
	persist: import.meta.env.SSR
		? undefined
		: {
				enabled: true,
				strategies: [{ key: "etma-oauth", storage: sessionStorage }],
		  },
	state: (): OAuthState => ({}),
	actions: {
		generateKey(returnUri?: string): string {
			const redirectKey = generateRandomString();

			this.$state = { [redirectKey]: { returnUri: returnUri ?? null } };

			return redirectKey;
		},
		verifyKey(redirectKey: string): boolean {
			return !!this.$state[redirectKey];
		},
		retrieveValue(redirectKey: string): OAuthItem | null {
			if (!this.$state[redirectKey]) return null;

			return this.$state[redirectKey] as OAuthItem;
		},
		clearValue(redirectKey: string): void {
			const keys = Object.keys(this.$state);

			if (keys.includes(redirectKey)) delete this.$state[redirectKey];
		},
		clearState() {
			this.$state = {};
		},
	},
});

export type OAuthStore = ReturnType<typeof useOAuthStore>;
