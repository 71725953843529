<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import type { RouteLocationRaw } from "vue-router";
import { useBreakpoints } from "~/utils";

defineProps<{
	btnLabel?: string;
	btnLabelShowOnMobile?: boolean;
	highlight?: boolean;
	to?: RouteLocationRaw;
	hasNotifications?: boolean;
}>();

const emit = defineEmits(["click"]);

const item = ref(null);
const open = ref(false);
const { isMobile } = useBreakpoints();

onClickOutside(item, () => (open.value = false));
onBeforeRouteLeave(() => {
	open.value = false;
});

const btnClick = (payload: MouseEvent) => {
	open.value = !open.value;
	emit("click", payload);
};
</script>

<template>
	<div
		ref="item"
		class="etma-app-header-actions-item"
		:class="{
			'etma-app-header-actions-item--highlight': highlight,
		}"
	>
		<span v-if="hasNotifications" class="etma-app-header-actions-item__notification"></span>
		<d-btn text :to="to" @click="btnClick">
			<span>
				<d-icon v-if="$slots.icon"><slot name="icon" /></d-icon>
				<span v-if="btnLabel && ((isMobile && btnLabelShowOnMobile) || !isMobile)">{{ btnLabel }}</span>
			</span>
		</d-btn>

		<div v-if="$slots.dropdown && open" class="etma-app-header-actions-item-dropdown">
			<slot name="dropdown" />
		</div>
	</div>
</template>

<style lang="scss">
.etma-app-header-actions-item {
	position: relative;

	&__notification {
		position: absolute;
		top: 0.75em;
		left: 1em;
		background-color: #f45;
		border-radius: 50%;
		height: 0.5em;
		width: 0.5em;
		z-index: 10000;
	}

	> .d-btn {
		line-height: var(--etma-app-header-line-height);
		padding: var(--etma-app-header-padding);

		> span {
			border-radius: var(--etma-app-header-item-border-radius);
			display: flex;
			padding: calc(var(--etma-app-header-padding) / 2) var(--etma-app-header-padding);
			transition: 0.2s ease-out;

			> span:nth-child(2) {
				margin-left: var(--etma-app-header-padding);
			}
		}

		.d-icon {
			font-size: calc(var(--etma-app-header-line-height) * 1);
			// padding: calc(var(--etma-app-header-line-height) * 0.13 / 2) 0;
		}

		&:hover {
			opacity: 1;

			> span {
				background: #cee0ce;
			}
		}
	}

	&-dropdown {
		background: #cee0ce;
		border-radius: var(--etma-app-header-item-border-radius);
		position: absolute;
		top: calc(var(--etma-app-header-line-height) + var(--etma-app-header-padding));
		right: 0;
		min-width: 100%;
	}

	&--highlight {
		> .d-btn {
			> span {
				background: var(--d-theme-color-primary);
				color: white;
			}

			&:hover {
				> span {
					color: black;
				}
			}
		}
	}
}
</style>
