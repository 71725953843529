<script setup lang="ts">
import { useSessionStore } from "~/store";
// import { useProducerLeadTypeform } from "~/utils";

// const { open: openProducerHook } = useProducerLeadTypeform();
const session = useSessionStore();
</script>

<template>
	<client-only>
		<ul class="etma-app-header-cta">
			<li v-if="!session.isProducer">
				<!-- <d-btn accent small @click="openProducerHook">{{ $t("app.header.cta.become-producer") }}</d-btn> -->
				<d-btn to="/producers/register" accent small>{{ $t("app.header.cta.become-producer") }}</d-btn>
			</li>
		</ul>
	</client-only>
</template>

<style lang="scss">
.etma-app-header-cta {
	display: flex;
	padding: 0 var(--etma-app-header-padding) 0 0;
	justify-content: flex-end;
	align-items: center;

	li {
		> a {
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			padding: var(--etma-app-header-padding);

			&.router-link-exact-active {
				text-decoration: underline;
			}
		}
	}
}
</style>
