<script setup lang="ts">
import { useSessionStore } from "~/store";
const session = useSessionStore();
</script>

<template>
	<d-overlay v-if="!session.cookieConsent" class="etma-overlays-cookie-consent">
		<d-alert>
			<t-overlays-cookie-consent />

			<d-btn medium primary @click="session.acceptCookieConsent">
				<span>{{ $t("generic.actions.accept") }}</span>
				<d-icon><icon-noto-v1-thumbs-up-medium-skin-tone /></d-icon>
			</d-btn>
		</d-alert>
	</d-overlay>
</template>

<style lang="scss">
.etma-overlays-cookie-consent {
	flex-direction: row-reverse;

	> .d-alert {
		margin: 2rem;
		height: fit-content;
		align-self: flex-end;
		justify-self: flex-end;
	}
}
</style>
