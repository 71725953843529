<script setup lang="ts">
import { useToast } from "~/utils";

const { toasts } = useToast();
</script>

<template>
	<div class="d-toasts-wrapper" v-if="toasts.length">
		<transition-group name="list" tag="ul">
			<d-toast v-for="toast of toasts" :key="toast.id" :toast="toast" />
		</transition-group>
	</div>
</template>

<style lang="scss">
.d-toasts-wrapper {
	z-index: 6000;
	width: 18rem;
	min-height: 6rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	bottom: 1rem;
	right: 2rem;
}

.list-enter-active,
.list-leave-active {
	transition: all 0.3s ease;
}

.list-enter,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}

.list-move {
	transition: transform 0.2s;
}
</style>
