import { Ref } from "vue";
import { useUUID } from "./use-uuid";

type ToastType = "success" | "error" | "default";
export interface ToastInterface {
	id?: string;
	type: ToastType;
	title: string;
	message?: string;
	timeout?: number;
}

const _toasts: Ref<ToastInterface[]> = ref([]);

const { createUUID } = useUUID();

export function useToast() {
	const add = (toast: ToastInterface) => {
		_toasts.value.push({
			...toast,
			id: createUUID(),
		});
	};
	const remove = (id: string) => {
		_toasts.value.splice(_toasts.value.indexOf(_toasts.value.find((t) => t.id === id)), 1);
	};
	const clear = () => {
		_toasts.value = [];
	};

	return {
		toasts: _toasts,
		add,
		remove,
		clear,
	};
}
