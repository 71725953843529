import { createPinia as _createPinia } from "pinia";
import createPiniaPersist from "pinia-plugin-persist";
import { PiniaSharedState } from "pinia-shared-state";
import type { App } from "vue";

export function createPinia(app: App) {
	// Set up store.
	const pinia = _createPinia();
	pinia.use(createPiniaPersist);

	// pinia.use(
	// 	PiniaSharedState({
	// 		type: "localstorage",
	// 	}),
	// );

	app.use(pinia);

	// Set up initial state.
	if (import.meta.env.SSR) {
		// initialState.pinia = pinia.state.value;
	} else {
		// console.log("Initial state:", initialState);
		// pinia.state.value = initialState.pinia;
	}

	return pinia;
}
