<script setup lang="ts">
import { useConfig } from "~/utils";
const { contactEmail } = useConfig();
</script>

<template>
	<footer class="etma-app-footer">
		<d-container>
			<div class="etma-app-footer-highlights">
				<etma-app-footer-highlight
					:lead-text="$t('about.haveQuestions')"
					:cta-text="$t('about.commonlyAskedQuestions')"
					:cta-to="{ name: 'faq' }"
				>
					<template #icon><icon-ph-circle-wavy-question-duotone /></template>
				</etma-app-footer-highlight>

				<etma-app-footer-highlight
					:lead-text="$t('app.footer.highlights.contact.header')"
					:cta-text="contactEmail"
					:cta-href="`mailto:${contactEmail}`"
				>
					<template #icon><icon-ic-twotone-email /></template>
				</etma-app-footer-highlight>
			</div>

			<div class="etma-app-footer-content">
				<nav>
					<!-- <h6>{{ $t("generic.menu") }}</h6> -->
					<ul>
						<li>
							<router-link :to="{ name: 'faq' }">{{ $t("about.faq.title") }}</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'about' }">{{ $t("about.aboutUs.title") }}</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'user-terms' }">{{ $t("about.userTerms.title") }}</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'about-privacy-policy' }">{{ $t("about.privacyPolicy.title") }}</router-link>
						</li>
					</ul>
				</nav>

				<p>{{ $t("about.copyright") }}</p>
			</div>
		</d-container>
	</footer>
</template>

<style lang="scss">
.etma-app-footer {
	$footer: &;
	background: #f6f6f3;
	margin: 8rem 0 0;
	padding: 3rem 0;
	max-width: 100vw;

	&-highlights {
		display: flex;
		flex-wrap: wrap;
		margin: -1rem 0 -1rem -1rem;

		#{$footer}-highlight {
			width: fit-content;
			margin: 0 2rem 0 0;
		}
	}

	&-content {
		margin-top: 3rem;
		border-top: 1px solid var(--d-theme-color-primary);
		padding-top: 3rem;

		> * + * {
			margin-top: 2rem;
		}
	}
}
</style>
