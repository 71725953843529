import { MaybeRef } from "@vueuse/core";
import { defineStore } from "pinia";
import { unref } from "vue";

import { useAppStore } from "./app";

export interface CartStoreLine {
	eventDateId?: number;
	productId: number;
	variationId: number;
	quantity: number;
}

export interface CartDonationLine {
	eventDateId?: number;
	amount: number;
}

export const useCartStore = defineStore("cart", {
	persist: import.meta.env.SSR
		? undefined
		: {
				enabled: true,
				strategies: [{ key: "etma-cart", storage: localStorage }],
		  },
	state: () => ({
		lines: [] as CartStoreLine[],
		donations: [{ eventDateId: null, amount: 20.0 }] as CartDonationLine[],
	}),
	getters: {
		/**
		 * Gets line matching event, product and variation ids.
		 */
		getLine: (state) => {
			return (p: { eventDateId?: number; productId: number; variationId: number }) => {
				return state.lines.find(
					(line) =>
						line.eventDateId === p.eventDateId && line.productId === p.productId && line.variationId === p.variationId,
				);
			};
		},

		/**
		 * Gets lines for event.
		 */
		getEventDateLines: (state) => {
			return (eventDateId: MaybeRef<number>) => {
				return state.lines.filter((line) => line.eventDateId === unref(eventDateId));
			};
		},

		/**
		 * Returns total price in product lines for event.
		 */
		totalPriceForEventDate() {
			return (
				eventDateId: MaybeRef<number>,
				products: {
					[id: number]: { variations: { id: number; price: number }[] };
				},
			) =>
				(this.getEventDateDonation(eventDateId)?.amount ?? 0) +
				this.getEventDateLines(eventDateId)
					.map((l) => l.quantity * (products[l.productId]?.variations?.find((v) => v.id === l.variationId)?.price ?? 0))
					.reduce((a, b) => a + b, 0);
		},

		/**
		 * Returns total quantity in product lines for event.
		 */
		totalQuantityForEventDate() {
			return (eventDateId: number) => {
				return this.getEventDateLines(eventDateId)
					.map((l) => l.quantity)
					.reduce((p, c) => p + c, 0);
			};
		},
	},
	actions: {
		/**
		 * Adds quantity for product to event cart.
		 */
		addToCart(p: { eventDateId?: number; productId: number; variationId: number; quantity?: number; set?: boolean }) {
			if (typeof p.eventDateId !== "number") {
				const { currentEventDateId } = useAppStore();
				p.eventDateId = currentEventDateId || undefined;
			}

			const line = this.getOrCreateLine(p);

			if (p.set && p.quantity !== undefined) line.quantity = p.quantity;
			else if (!p.set) line.quantity += p.quantity ?? 1;

			const index = this.lines.findIndex((l) => l === line);

			if (line.quantity === 0) {
				this.lines.splice(index, 1);
			} else {
				this.lines.splice(index, 1, { ...line });
			}
		},

		/**
		 * Empties the cart for given event.
		 */
		emptyEventDateCart(eventDateId: MaybeRef<number>) {
			this.lines = this.lines.filter((line) => line.eventDateId !== unref(eventDateId));
		},

		/**
		 * Gets donation for event.
		 */
		getEventDateDonation(eventDateId: MaybeRef<number>) {
			let donation = this.donations.find((d) => d.eventDateId === unref(eventDateId));
			if (!donation) donation = this.setDonationAmount(unref(eventDateId), 0);
			return donation;
		},

		/**
		 * Sets donation amount for current cart.
		 */
		setDonationAmount(eventDateId: number, amount: number) {
			let donation = this.donations.find((d) => d.eventDateId === unref(eventDateId));

			if (!donation) {
				donation = { eventDateId, amount };
				this.donations.push(donation);
			} else {
				donation.amount = amount;
				// this.donations = this.donations.map((d) => (d.eventDateId === eventDateId ? donation : d));
			}

			return donation;
		},

		/**
		 * Gets line matching event, product and variation, or creates, adds and
		 * returns the line.
		 */
		getOrCreateLine(p: { eventDateId?: number; productId: number; variationId: number }) {
			// Get exisiting line.
			let line = this.getLine(p);

			// Push new if no exisiting was found.
			if (!line) {
				line = { ...p, quantity: 0 };
				this.lines.push(line);
			}

			// Return found or created line.
			return line;
		},

		/**
		 * Removes missing lines from event cart, where a match with `valid` is not
		 * found.
		 */
		removeMissing(eventDateId: number, valid: { productId: number; variationId: number }[]) {
			const lines = this.getEventDateLines(eventDateId);

			for (const line of lines) {
				const found = valid.find((v) => v.productId === line.productId && v.variationId === line.variationId);

				if (!found) this.addToCart({ ...line, quantity: 0, set: true });
			}
		},
	},
});

export type CartStore = ReturnType<typeof useCartStore>;
