import { RouteRecordNormalized } from "vue-router";
import { ModuleInstallFunctionSignature } from "~/interfaces";

function showEnvVars() {
	console.groupCollapsed("%cEnvironment variables", "color: #ffc107; font-size: 20px; font-weight: 900;");
	console.table(import.meta.env);
	console.groupEnd();
}

function showRoutes(routes: RouteRecordNormalized[]) {
	console.groupCollapsed("%cRoutes", "color: #ffc107; font-size: 20px; font-weight: 900;");
	console.table(
		routes.map((r) => ({
			path: r.path,
			name: r.name,
			meta: JSON.stringify(r.meta),
		})),
	);
	console.groupEnd();
}

export const install: ModuleInstallFunctionSignature = ({ router }) => {
	if (import.meta.env.DEV) {
		showEnvVars();
		showRoutes(router.getRoutes());
	}
};
