<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";

interface BtnProps {
	type?: "link" | "button" | "submit" | "reset";
	to?: RouteLocationRaw;

	// Style modifiers
	accent?: boolean;
	primary?: boolean;
	text?: boolean;
	icon?: boolean;

	// Size modifiers
	tiny?: boolean;
	small?: boolean;
	large?: boolean;
	huge?: boolean;
	fill?: boolean;
}

type BtnPropsKeys = keyof BtnProps;

const props = defineProps<BtnProps>();

const type = computed(() => {
	if (props.to && (props.type === "link" || props.type === undefined)) return "link";
	else return props.type ?? "button";
});

const classProps: BtnPropsKeys[] = ["icon", "accent", "primary", "text", "tiny", "small", "large", "huge", "fill"];

const classes = computed(() => {
	const c = ["d-btn"];
	classProps.forEach((m) => {
		if (props[m]) c.push(`d-btn--${m}`);
	});
	return c.join(" ");
});
</script>

<template>
	<router-link v-if="type === 'link'" v-bind="$attrs" :class="classes" :to="to">
		<slot />
	</router-link>

	<button v-else v-bind="$attrs" :class="classes" :type="type">
		<slot />
	</button>
</template>

<style lang="scss">
.d-btn {
	background: #ddd;
	border-radius: 0.3rem;
	box-sizing: border-box;
	cursor: pointer;
	color: var(--d-theme-color-base);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 0.8em;
	position: relative;
	transition: all 0.2s ease-in-out;

	> * + * {
		margin-left: 0.15em;
	}

	&:before {
		border-radius: 0.3rem;
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: white;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	&:hover {
		text-decoration: none;
		// transform: scale(1.02);

		&:before {
			opacity: 0.1;
		}
	}

	&--accent {
		background: var(--d-theme-color-accent);
		color: var(--d-theme-color-accent-contrast);
	}

	&--primary {
		background: var(--d-theme-color-primary);
		color: var(--d-theme-color-primary-contrast);
	}

	&--text {
		background: none;
		color: var(--d-theme-color-base);

		&.d-btn--primary {
			color: var(--d-theme-color-primary);
		}
	}

	&--tiny {
		font-size: 0.7em;
	}

	&--small {
		font-size: 0.8em;
	}

	&--large {
		font-size: 1.2em;
		padding: 0.7em 1.2em;
	}

	&--huge {
		border-radius: 1.4rem;
		font-size: 1.6em;
		font-weight: bold;
		padding: 0.7em 1.2em;

		&:before {
			border-radius: 1.4rem;
		}
	}

	&--fill {
		width: 100%;
	}

	&--icon {
		padding: 0.2em;
		border-radius: 50%;

		&:before {
			border-radius: 50%;
		}

		> * {
			display: block;
		}

		&.d-btn--large {
			padding: 0.4em;

			> * {
				font-size: 1em;
			}
		}
	}
}
</style>
