<script setup lang="ts">
import { createNotificationsStore } from "~/store";
const notifications = createNotificationsStore();
</script>

<template>
	<etma-app-header-actions-item>
		<template #icon>
			<icon-ph-bell-ringing-duotone v-if="notifications.hasNotifications" />
			<icon-ph-bell-duotone v-else color="grey" />
		</template>

		<template v-if="notifications.hasNotifications" #dropdown>
			<d-menu>
				<d-menu-item
					v-for="invite in notifications.invitations"
					:key="invite.id"
					:to="{
						name: 'producers-producerSlug-account-events-invitations-eventDateId',
						params: { producerSlug: invite.producer.slug, eventDateId: invite.eventDate.id },
					}"
				>
					&#183; Received invitation for {{ invite.producer.name }} at {{ invite.eventDate.event.name }} at
					{{ $d(invite.eventDate.start, "medium") }}
				</d-menu-item>
			</d-menu>
		</template>
	</etma-app-header-actions-item>
</template>
