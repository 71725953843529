import { gql } from "@urql/core";
import { useMutation, useQuery } from "@urql/vue";
import { useJwt } from "@vueuse/integrations/useJwt";
import { defineStore } from "pinia";

import { ProducerInterface, UserInterface } from "~/api/interfaces";
import { useOAuthStore } from "./oauth";

const userQuery = gql`
	query GetSessionUser {
		user {
			id
			email
			phoneNumber

			name {
				given
				family
			}

			address {
				postalCode
			}

			flags {
				hasGivenCookieConsent
			}

			producers {
				id
				name
				slug
			}
		}
	}
`;

const updateCookieConsentMutation = gql`
	mutation updateCookieConsent($consent: Boolean!) {
		updateCookieConsent(consent: $consent)
	}
`;

export interface JwtToken {
	producers: ProducerInterface[];
	user: JwtTokenUser;
}

export interface JwtTokenUser {
	email: string;
	isAdmin: boolean;
}

export interface SessionStoreProducerPreview {
	id: string;
	name: string;
}

let _refreshQuery = async () => {};
let executeCookieConsentMutation: any;

export function createSessionStore(): SessionStore {
	const session = useSessionStore();

	// session.cookieConsent = false; // TODO: Delete

	const { data, executeQuery } = useQuery<{ user: UserInterface | null }, undefined>({
		query: userQuery,
		requestPolicy: "network-only",
		pause: computed(() => !session.isLoggedIn),
	});

	const { executeMutation } = useMutation<{ updateCookieConsent: boolean }, { consent: boolean }>(
		updateCookieConsentMutation,
	);
	executeCookieConsentMutation = executeMutation;

	// watch(data, () => session.setUser(data.value?.user), { immediate: true });
	watch(data, () => session.setUser(data.value?.user));

	_refreshQuery = async () => {
		await executeQuery();
	};

	return session;
}

export const useSessionStore = defineStore("session", {
	persist: import.meta.env.SSR
		? undefined
		: {
				enabled: true,
				strategies: [{ key: "etma-session", storage: localStorage }],
		  },
	state: () => ({
		token: null as string | null,
		decoded: null as JwtToken | null,
		user: null as UserInterface | null,
		producerPreview: null as SessionStoreProducerPreview | null,
		betaConfirmation: false,
		localCookieConsent: false,
	}),
	getters: {
		cookieConsent: ({ user, localCookieConsent }) => user?.flags?.hasGivenCookieConsent ?? localCookieConsent,
		isAdmin: ({ decoded }) => decoded?.user.isAdmin ?? false,
		isLoggedIn: ({ token }) => !!token,
		isEventOrganizer: ({ user }) => !!(user?.events?.length ?? 0 > 0),
		isProducer: ({ user }) => !!(user?.producers?.length ?? 0 > 0),
	},
	actions: {
		async refreshUser() {
			return _refreshQuery();
		},

		async acceptCookieConsent() {
			if (!this.user) return (this.localCookieConsent = true);
			await executeCookieConsentMutation({ consent: true });
			await this.refreshUser();
		},

		acceptBeta() {
			this.betaConfirmation = true;
		},

		async setToken(token: string) {
			if (!token || typeof token !== "string") throw new TypeError("token must be string");

			const decoded = useJwt<{ user: JwtTokenUser; producers: ProducerInterface[] }>(token);

			if (!decoded.payload.value) throw new TypeError("invalid decoded JWT");

			this.token = token;
			this.decoded = decoded.payload.value;
		},

		setProducerPreview(preview: string | null) {
			if (preview === null) {
				this.producerPreview = null;
				return;
			}

			try {
				const producerPreview = JSON.parse(atob(preview));

				if (typeof producerPreview.id === "string" && typeof producerPreview.name === "string") {
					this.producerPreview = producerPreview;
				}
			} catch (e) {
				/** noop */
			}
		},

		setUser(user: UserInterface | null | undefined) {
			this.user = user || null;
			// if (this.localCookieConsent === true) this.acceptCookieConsent();
		},

		async unsetToken() {
			useOAuthStore().clearState();
			this.user = null;
			this.token = null;
			this.decoded = null;
		},

		isMemberOfProducer(id?: number) {
			return !!this.user?.producers?.find((p) => p.id === id);
		},

		isAdminOfEvent(id?: number) {
			return this.isAdmin || this.isMemberOfProducer(id);
		},
	},
});

export type SessionStore = ReturnType<typeof useSessionStore>;
