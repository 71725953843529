<script setup lang="ts">
import { computed, useCssVars } from "vue";
import { RouteLocationNormalizedLoaded, useRoute } from "vue-router";
import type { RouteLocationRaw } from "vue-router";

import dBtn from "./btn.vue";

const props = defineProps<{
	text?: string;
	to?: RouteLocationRaw;
}>();

const emits = defineEmits<{
	click: MouseEvent;
}>();

const route = useRoute();

useCssVars(() => ({
	"d-menu-item--active": isCurrentRoute.value ? "var(--d-theme-color-light)" : "",
}));

const isCurrentRoute = computed(() => {
	const to = (props.to as RouteLocationNormalizedLoaded)?.name?.toString().split("-").slice(0, 4).join("-");
	const current = route.name?.toString().split("-").slice(0, 4).join("-");
	return to === current;
});

const attrs = useAttrs();
const hasClickEvent = computed(() => !!attrs.onClick);
</script>

<template>
	<li class="d-menu-item" :class="{ 'd-menu-item--clickable': hasClickEvent }" @click.prevent="emits.click">
		<component :is="to ? dBtn : 'div'" :to="to" :text="!!to || undefined">
			<d-icon v-if="$slots['icon']" class="d-menu-item__icon">
				<slot name="icon" />
			</d-icon>

			<span class="d-menu-item__body"
				><slot>{{ text }}</slot></span
			>
		</component>
	</li>
</template>

<style lang="scss">
.d-menu-item {
	background-color: var(--d-menu-item--active);

	> a,
	> div,
	> .d-btn {
		align-items: center;
		border: none;
		border-radius: 0;
		display: flex;
		padding: var(--d-menu-item-padding, 0.4em var(--d-base-margin));
	}

	a,
	.d-btn {
		color: var(--d-theme-color-primary);
	}

	&__icon {
		color: var(--d-menu-item-icon-color, var(--d-theme-color-base));
		width: 1em;
		height: 1em;
		margin: 0 0.6em 0 0;
	}

	&__body {
		flex: 1;
		white-space: nowrap;
	}

	> a:hover {
		background: var(--d-menu-item-link-hover-background, var(--d-theme-color-light));
		text-decoration: none;
	}

	&--clickable {
		cursor: pointer;

		&:hover {
			background: #eee;
		}
	}

	// & > a.router-link-active {
	// 	background: #ddd;
	// }
}
</style>
