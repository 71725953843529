import { createCookies as vueCreateCookies, useCookies as vueUseCookies } from "@vueuse/integrations/useCookies";
import { IncomingMessage } from "connect";

// The useCookies function to use. Uses `useCookies` in browsers; and
// function returned by `createCookies` on server, with request cookies parsed.
let useCookiesFn = vueUseCookies;

/**
 * Configures the useCookies function to use from request.
 */
export function createCookies(request?: IncomingMessage) {
	useCookiesFn = vueCreateCookies(request);
	return useCookiesFn;
}

/**
 * Creates an instance of universal-cookies with reactive values.
 */
export function useCookies(
	dependencies?: Parameters<typeof useCookiesFn>[0],
	options?: Parameters<typeof useCookiesFn>[1],
	cookies?: Parameters<typeof useCookiesFn>[2],
) {
	return useCookiesFn(dependencies, options, cookies);
}
