import { MaybeRef } from "@vueuse/core";
import { Hits, SearchResponse } from "meilisearch";

import { meilisearch } from ".";

export function useSearch<T = any>(index: string, query: MaybeRef<string>, options?: { throttle?: boolean | number }) {
	let throttle = options?.throttle ?? true;
	if (typeof throttle !== "number" && throttle !== false) throttle = 50;

	if (!isRef(query)) query = ref(query);

	const throttled = throttle ? useThrottle(query, throttle) : query;
	const hits = ref<null | Hits<T>>(null);
	const response = ref<SearchResponse<T> | null>(null);

	watch(throttled, async (query) => {
		const res = (query && (await meilisearch.index(index).search<T>(query))) || null;
		response.value = res as any;
		hits.value = res?.hits ?? (null as any);
	});

	return { hits, response };
}
