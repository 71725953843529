import { gql, useQuery } from "@urql/vue";
import { MaybeRef } from "@vueuse/core";
import type { Ref } from "vue";
import { ProductCategoryInterface, ProductInterface } from "~/api/interfaces";

import { useEventDate } from "~/utils";
import { useCartStore } from "./cart";

const query = gql`
	query ($productIds: [Int!]!) {
		products(ids: $productIds) {
			id
			name
			slug

			asset {
				... on MediaAssetInterface {
					publicId
				}
			}

			producer {
				id
				name
				slug
			}

			category {
				id
				name
				slug
				parent {
					id
					slug
				}
			}

			price {
				base
				measure
				approx
			}

			variations {
				id
				amount
				quantity
				price
				stock
			}
		}
	}
`;

export const useEventCartStore = (eventDateId?: MaybeRef<number | undefined>) => {
	if (!eventDateId) eventDateId = useEventDate().eventDateId;
	if (!eventDateId) throw new Error("must be used within an event route, or provide eventId");
	if (!isRef(eventDateId)) eventDateId = ref(eventDateId);

	const cart = useCartStore();
	const lines = computed(() => {
		const id = unref(eventDateId);
		return id ? cart.getEventDateLines(id) : [];
	});

	const productIds = computed(() => lines.value.map((l) => l.productId));
	const { data, executeQuery } = useQuery<{
		products: ProductInterface[];
	}>({
		query,
		variables: { productIds },
		pause: computed(() => !productIds.value.length),
	});

	const products = computed(() => {
		const ret = {} as { [id: number]: ProductInterface };

		if (data.value?.products) {
			for (const product of data.value.products) ret[product.id] = product;
		}

		return ret;
	});

	const categories = computed(() => {
		if (!products.value) return [];
		const all = Object.values(products.value).map((p) => p.category);
		const unique = [] as ProductCategoryInterface[];
		for (const category of all) {
			if (!unique.find((c) => c.id === category.id)) unique.push(category);
		}
		return unique;
	});

	const linesForCategory = computed(() => {
		return (categoryId: number) => {
			return lines.value.filter((l) => {
				if (!products.value || !products.value[l.productId]) return false;
				return products.value[l.productId].category.id === categoryId;
			});
		};
	});

	watch(cart, async () => {
		await executeQuery();
		if (data.value?.products) removeMissing(products.value);
	});

	const removeMissing = (ret: {
		[id: number]: {
			id: number;
			variations: { id: number }[];
		};
	}) => {
		const id = unref(eventDateId);
		if (!id) return;

		const valid: {
			productId: number;
			variationId: number;
		}[] = [];

		for (const product of Object.values(ret)) {
			for (const { id: variationId } of product.variations) {
				valid.push({
					productId: product.id,
					variationId,
				});
			}
		}

		cart.removeMissing(id, valid);
	};

	return { cart, categories, eventDateId: eventDateId as Ref<number>, lines, linesForCategory, products };
};
