<script setup lang="ts">
import { useSessionStore } from "~/store";
const session = useSessionStore();
</script>

<template>
	<etma-app-header-actions-item :btn-label="$t('term.producer')" :has-notifications="false">
		<template #icon><icon-ph-arrow-square-right-duotone /></template>

		<template #dropdown>
			<d-menu>
				<d-menu-item
					v-for="producer in session.user?.producers"
					:key="producer.id"
					:text="producer.name"
					:to="{
						name: 'producers-producerSlug-account',
						params: { producerSlug: producer.slug },
					}"
				/>
			</d-menu>
		</template>
	</etma-app-header-actions-item>
</template>

<style lang="scss">
.etma-app-header-producer-account-picker {
	$root: &;
	position: relative;

	&__menu {
		display: none;
		background: #eee;
		position: absolute;
		top: 100%;
		right: 0;
	}

	&--open {
		.d-btn {
			background-color: #ddd;
		}

		#{$root}__menu {
			display: block;
		}
	}
}
</style>
