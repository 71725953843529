import { gql, useQuery } from "@urql/vue";
import { MaybeRef } from "@vueuse/core";
import { computed, inject, InjectionKey, provide, ref, Ref, unref } from "vue";

import { EventInterface, ProductCategoryInterface } from "~/api/interfaces";

import { useCrumbs, CrumbsDefinition } from "./use-breadcrumbs";
import { useCategory } from "./use-category";

const query = gql`
	query ($eventSlug: String!, $after: DateTime!) {
		event(slug: $eventSlug) {
			id
			name
			slug

			dates(after: $after) {
				id
				start
				end
			}

			location {
				address {
					street
					postalCode
					city
					region
				}
			}
		}
	}
`;

export const EventInjectionKey: InjectionKey<Ref<EventInterface>> = Symbol();

export function provideEvent(eventSlug: Ref<string | undefined>) {
	const after = new Date();

	const { data, executeQuery } = useQuery<{ event: EventInterface }>({
		query,
		variables: { after, eventSlug },
		pause: computed(() => !eventSlug.value),
	});

	const event = computed(() => data.value?.event);
	provide(EventInjectionKey, event);

	return { event, executeQuery };
}

export function useEvent() {
	const event = inject(EventInjectionKey, null);
	const eventName = computed(() => unref(event)?.name);
	const eventId = computed(() => unref(event)?.id);
	const eventSlug = computed(() => unref(event)?.slug);
	return { event, eventName, eventId, eventSlug };
}

export function useEventCrumbs(crumbs: CrumbsDefinition) {
	const event = useEvent().event;
	if (!event) return [];
	return useCrumbs({
		"events-eventSlug": computed(() => unref(event)?.name),
		...Object.fromEntries(
			Object.entries(crumbs).map(([name, text]) => [name.startsWith("_") ? name : `events-eventSlug-${name}`, text]),
		),
	});
}

export function useEventShopCategoryCrumbs(category?: MaybeRef<ProductCategoryInterface | undefined>) {
	if (!category) category = useCategory().category;
	category = unref(category);
	if (!category) return [];

	const crumbs: { [name: string]: MaybeRef<string> } = {
		shop: "shop.allProducts",
	};
	if (category.parent) crumbs["shop-categorySlug"] = ref(category.parent.name);
	crumbs._ = ref(category.name);

	return useEventCrumbs(crumbs);
}
